import { createStore } from 'vuex'

export default createStore({
  state: {
    domain: "https://xcy.kooshui.com",
    api:"https://xcy.kooshui.com/api/v1",
    // api:"http://127.0.0.1:21608/api/v1",
    
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
