import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: "挂号预约",
      tabBarShow: true,
      active: 0,
    },
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/About.vue"),
    meta: {
      title: "医院简介",
      tabBarShow: true,
      active: 1,
    },
  },
  {
    path: "/personal",
    name: "Personal",
    component: () => import("../views/Personal.vue"),
    meta: {
      title: "个人中心",
      tabBarShow: true,
      active: 2,
    },
  },
  {
    path: '/mark',
    name: 'Mark',
    component: () => import('../views/Mark.vue'),
    meta: {
      title: "预约科室",
      tabBarShow: false,
      active: 0,
    },
  },
  {
    path: '/orders',
    name: 'OrdersList',
    component: () => import('../views/OrdersList.vue'),
    meta: {
      title: "我的预约",
      tabBarShow: false,
      active: 1,
    },
  },
  {
    path: '/income',
    name: 'Income',
    component: () => import('../views/Income.vue'),
    meta: {
      title: "我的邀请",
      tabBarShow: false,
      active: 1,
    },
  },
  {
    path: '/share',
    name: 'Share',
    component: () => import('../views/Share.vue'),
    meta: {
      title: "分享",
      tabBarShow: false,
      active: 1,
    },
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('../views/News.vue'),
    meta: {
      title: "科室介绍",
      tabBarShow: false,
      active: 1,
    },
  },
  {
    path: "/withdraw",
    name: "Withdraw",
    component: () => import("../views/Withdraw.vue"),
    meta: {
      title: "积分兑换",
      tabBarShow: false,
      active: 1,
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
