import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'vant/lib/index.css';
import {
    Button,
    Tabbar,
    TabbarItem,
    Tab, Tabs,
    Col, Row,
    Form, Field, CellGroup,
    Icon,
    Toast,
    CountDown,
    NavBar,
    Popover,
    Cell,
    Grid, GridItem,
    Divider,
    Search,
    Empty,
    DropdownMenu, DropdownItem,
    Collapse, CollapseItem,
    List,
    Sticky,
    Tag,
    Switch,
    Dialog,
    DatePicker,
    Calendar,
    Picker,
    Popup,
    ActionBar, ActionBarIcon, ActionBarButton,
    Image as VanImage,
    Lazyload,
    Uploader,
    RadioGroup, Radio,
    Checkbox, CheckboxGroup,
    Overlay,
    Swipe, SwipeItem,
    PullRefresh,
    Stepper,
    Step, Steps,
    Cascader,
    NoticeBar,
    SwipeCell,
    Progress,
    Rate,
    Loading,
} from 'vant';

const app = createApp(App)
app.use(Button)
app.use(Tabbar)
app.use(TabbarItem)
app.use(Tab);
app.use(Tabs);
app.use(Col);
app.use(Row);
app.use(Form);
app.use(Field);
app.use(CellGroup);
app.use(Icon);
app.use(Toast);
app.use(CountDown);
app.use(NavBar);
app.use(Popover);
app.use(Cell);
app.use(Grid);
app.use(GridItem);
app.use(Divider);
app.use(Search);
app.use(Empty);
app.use(DropdownMenu);
app.use(DropdownItem);
app.use(Collapse);
app.use(CollapseItem);
app.use(List);
app.use(Sticky);
app.use(Tag);
app.use(Switch);
app.use(Dialog);
app.use(DatePicker);
app.use(Calendar);
app.use(Picker);
app.use(Popup);
app.use(ActionBar);
app.use(ActionBarIcon);
app.use(ActionBarButton);
app.use(VanImage);
app.use(Lazyload);
app.use(Uploader);
app.use(Radio);
app.use(RadioGroup);
app.use(Checkbox);
app.use(CheckboxGroup);
app.use(Overlay);
app.use(Swipe);
app.use(SwipeItem);
app.use(PullRefresh);
app.use(Stepper);
app.use(Step);
app.use(Steps);
app.use(Cascader);
app.use(NoticeBar);
app.use(SwipeCell);
app.use(Progress);
app.use(Rate);
app.use(Loading);
app.use(store).use(router)
app.use(VueAxios, axios)

app.mount('#app')
