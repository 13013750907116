<template>
  <div class="home">
    <div class="head">
      <div class="logo">
        <van-image
          round
          width="6rem"
          height="6rem"
          :src="require('../assets/logo.jpg')"
        />
        <div>廊坊市新朝阳医院</div>
      </div>
    </div>
    <div class="main">
      <van-cell-group inset>
        <van-cell title="预约挂号" icon="like-o" />
        <van-row justify="center" class="list">
          <van-col span="22" v-for="(item, index) in dataList" :key="index">
            <van-cell center :title="item.name" :label="item.memo">
              <template #value>
                <van-button
                  v-if="item.url"
                  :url="item.url"
                  type="primary"
                  size="mini"
                  >科室介绍</van-button
                >
                <van-button @click="goDesc(item)" type="success" size="mini"
                  >预约挂号</van-button
                >
              </template>
            </van-cell>
          </van-col>
        </van-row>
      </van-cell-group>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      Authorization: "",
      dataList: [],
      // dataList: [
      //   { id: "1", name: "CT", desc: "简介" },
      //   { id: "2", name: "核磁", desc: "简介" },
      //   { id: "3", name: "彩超", desc: "简介" },
      //   { id: "4", name: "TMT热成像全身扫描", desc: "简介" },
      //   { id: "5", name: "中医科微创清淤", desc: "简介" },
      //   { id: "6", name: "消化科无痛胃肠镜", desc: "简介" },
      //   { id: "7", name: "全身体检套餐", desc: "简介" },
      //   { id: "8", name: "妇科检查", desc: "简介" },
      //   { id: "9", name: "内科门诊或住院", desc: "简介" },
      //   { id: "10", name: "外科门诊或住院", desc: "简介" },
      //   { id: "11", name: "康复科", desc: "简介" },
      // ],
    };
  },
  mounted() {

    if (localStorage.getItem(window.lsn)) {
      this.Authorization = localStorage.getItem(window.lsn);
    }
    // 去除微信默认参数
    if (
      /from=[^&$?]{1,}(&|$)/.test(location.search) ||
      /isappinstalled=[^&$?]{1,}(&|$)/.test(location.search)
    ) {
      let newSearch = null;
      newSearch = location.search
        .replace(/from=[^&$?]{1,}(&|$)/, "")
        .replace(/isappinstalled=[^&$?]{1,}(&|$)/, "")
        .replace(/&$|\?$/, "");
      let newUrl = null;
      newUrl = location.origin + location.pathname + newSearch + location.hash;
      location.replace(newUrl);
    } else {
      this.init();
    }
  },
  methods: {
    compareById(a, b) {
      return a.id - b.id;
    },
    init() {
        this.getUserInfo();
      // 获取科室
      this.axios({
        url: this.$store.state.api + "/goods",
        method: "get",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: this.Authorization,
        },
      }).then((res) => {
        if (res.data) {
          this.dataList = res.data;
        }
      });
      setTimeout(() => {
        // 添加访问记录
        this.axios({
          url:
            this.$store.state.api +
            "/my/infoadd?seed=" +
            localStorage.getItem(window.ref),
          method: "get",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: this.Authorization,
          },
        }).then((res) => {
          if (res.data) {
            console.log(res.data);
          }
        });
      }, 3000);
      // $this.$axios
      //   .get(this.$store.state.api + "/goods")
      //   .then(function (response) {
      //     if (response.data) {
      //       $this.couponList = response.data;
      //     }
      //   });
      // $this.$axios
      //   .get(this.$store.state.api + "/my/avatarinfo")
      //   .then(function (response) {
      //     if (response.data) {
      //       if (response.data.superiorCode) {
      //         $this.userinfo = response.data.userinfo;
      //         $this.code = response.data.invitationCode;
      //       } else {
      //         $this.$axios
      //           .get(
      //             $this.$store.state.api +
      //               "/she/avatarinfo?code=" +
      //               localStorage.getItem(window.ref)
      //           )
      //           .then(function (response2) {
      //             if (response2.data) {
      //               $this.userinfo = response2.data.userinfo;
      //             }
      //           });
      //         $this.code = localStorage.getItem(window.ref);
      //       }
      //       if ($this.userinfo.nickname.length > 5) {
      //         let nikeArr = Array.from($this.userinfo.nickname);
      //         $this.userinfo.nickname = nikeArr.slice(0, 5).join("") + "...";
      //       }
      //     }
      //     $this.configShare();
      //   });
    },

    getUserInfo() {
      let $this = null;
      $this = this;
      // 获取个人信息
      this.axios({
        url: this.$store.state.api + "/my/info",
        method: "get",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: this.Authorization,
        },
      }).then((res) => {
        if (res.data) {
          $this.userInfo = res.data;
          if (!$this.userInfo.userinfo) {
            window.sign(window.location.href);
          } else {
            $this.userInfo.headImg = $this.userInfo.userinfo.headimgurl;
            $this.userInfo.nickname = $this.userInfo.userinfo.nickname;
          }
        }
      });
    },
    // configShare() {
    //   let $this = null;
    //   $this = this;
    //   // 分享配置
    //   $this.$axios
    //     .get($this.$store.state.wxApi + "/wxsign?url=" + window.location.href)
    //     .then(function (wxresponse) {
    //       window.wxConfig.timestamp = wxresponse.data.timestamp;
    //       window.wxConfig.nonceStr = wxresponse.data.nonceStr;
    //       window.wxConfig.signature = wxresponse.data.signature;
    //       window.wx.config(window.wxConfig); // wx初始化配置
    //       window.wx.ready(function () {
    //         //需在用户可能点击分享按钮前就先调用
    //         window.wx.updateAppMessageShareData({
    //           title: "巧媳妇餐饮", // 分享标题
    //           desc: "巧媳妇餐饮", // 分享描述
    //           link: $this.$store.state.domain + "/?code=" + $this.code, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
    //           imgUrl: $this.$store.state.domain + "/img/element/avatar.png", // 分享图标
    //           success: function () {
    //             // 设置成功
    //           },
    //         });
    //         window.wx.updateTimelineShareData({
    //           title: "巧媳妇餐饮", // 分享标题
    //           link: $this.$store.state.domain + "/?code=" + $this.code, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
    //           imgUrl: $this.$store.state.domain + "/img/element/avatar.png", // 分享图标
    //           success: function () {
    //             // 设置成功
    //           },
    //         });
    //       });
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // },
    goDesc(item) {
      console.log(item);
      this.$router.push({
        name: "Mark",
        query: { name: item.name, id: item._id },
      });
    },
    goNews(item) {
      this.$router.push({
        name: "News",
        query: { title: item.name, url: item.url },
      });
    },
  },
};
</script>

<style lang="scss">
.home {
  background: #f9f9f9;
  .head {
    height: 200px;
    background-image: linear-gradient(#24dea1, #1fd3b3);
    border-radius: 0 0 20px 20px;
    overflow: hidden;
    .logo {
      padding-top: 30px;
      font-size: 20px;
      font-weight: 200;
      color: #fff;
      letter-spacing: 2px;
      line-height: 40px;
    }
  }
  .main {
    padding-top: 20px;
    text-align: left;
    padding-bottom: 60px;
    .list {
      padding: 10px;

      .van-col {
        margin-bottom: 10px;
        .van-cell {
          background: #20d3b213;
          border: 1px solid #98d2c778;
          border-radius: 5px;
          box-shadow: #ddd 1px 2px;
        }
      }
    }
  }
}
</style>
