<template>
  <van-tabbar
    v-if="$route.meta.tabBarShow"
    active-color="#3573fa"
    inactive-color="#000"
    v-model="active"
  >
    <van-tabbar-item icon="home-o" replace to="/">挂号预约</van-tabbar-item>
    <van-tabbar-item icon="shield-o" replace to="/about">医院简介</van-tabbar-item>
    <van-tabbar-item icon="setting-o" replace to="/personal"
      >个人中心</van-tabbar-item
    >
  </van-tabbar>
  <router-view v-slot="{ Component }">
    <transition>
      <keep-alive :include="['Home', 'Mark', 'News']">
        <component :is="Component" />
      </keep-alive>
    </transition>
  </router-view>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
      transitionName: "van-slide-right",
    };
  },
  watch: {
    // $route(to, from) {
    //   const toDepth = to.path.split("/").length;
    //   const fromDepth = from.path.split("/").length;
    //   if (toDepth === fromDepth) {
    //     this.transitionName = "";
    //   } else {
    //     this.transitionName =
    //       toDepth < fromDepth ? "van-slide-left" : "van-slide-left";
    //   }
    //   console.log(toDepth, fromDepth);
    // },
    $route(to, from) {
      this.active = to.meta.active;
      //console.log(to,from);
    },
  },
  mounted() {
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: #f9f9f9;
  height: 100vh;
}
</style>
